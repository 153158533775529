
.site-layout {
  background: #fff;
}

.site-layout-background {
  background: #fff;
  padding: 0;
  border-bottom:1px #E0E0E0 solid;
}

.site-layout-header {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.site-layout-content {
  margin-left: 45px;
  margin-top: 38px;
}

.ant-menu-item-selected {
  border-right: 3px solid #63F2D3 !important;
}


.site-page-header {
  padding-left: 0;
}

.site-page-header span {
  padding-left: 0;
  color: #224681;
}

.custom-card {
  border-radius: 10px;
}

.custom-card.custom-card.full {
  border: 0px solid #04ECCD;
}

.custom-card.custom-card.cobrador {
  border: 0px solid #FF9500;
}

.active {
  border-width: 2px !important;
}

.custom-card.full .ant-card-head {
  background-color: #D9FCF7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.custom-card.cobrador .ant-card-head {
  background-color: #FEF4DE;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.card-title {
  padding-left: 10px;
}

.buttons-row {
  margin-top: 20px;
  border-top: 1px solid #E0E0E0;
  padding: 10px;
}

.collaborator-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #224681;
  height: 100vh;
}

.collaborator-confirm-container-card {
  background-color: white;
  padding: 50px;
  border-radius: 6px;
  max-width: 50%;
}