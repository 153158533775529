.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #224681;
  height: 100vh;
}

.login-container-app {
  background-color: white;
  padding: 50px;
  border-radius: 6px;
  max-width: min-content;
}

.text-containter {
  color: black;
  text-align: center;
}

.avatar-container {
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container {
  margin: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  margin: 15px;
  display: flex;
  width: fit-content;
}

.login-content-container {
  height: 100%;
}

.input-style {
  width: 100%;
}

.button-style {
  width: 100%;
}
