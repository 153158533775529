/* -- code to use Sarala font-family in theme -- */

@font-face {
  font-family: 'Sarala';
  src: url('assets/fonts/Sarala-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sarala';
  src: url('assets/fonts/Sarala-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
/* - end - */


body {
  margin: 0;
  font-family: 'Sarala', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Sarala', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
